export enum PostStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published'
}

export enum PostSticky {
  YES = 1,
  NO = 0
}

export interface Post {
  id: number
  title: string
  cover: string
  cover_link: string
  is_sticky: PostSticky
  status: PostStatus
  published_at: string
  created_at: string
  content: string
}

export type PartialPost = Partial<Post>;
